import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image";
import {Helmet} from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import Outlink from "../components/Outlink";

class PhysicalPostTemplate extends React.Component {

  render() {
    const {
      content,
      contentComponent,
      title,
      helmet,
      buyLink,
      images
    } = this.props;
    const PostContent = contentComponent || Content

    return (
      <section>
        {helmet || ''}
        <div>
          {
            images.map(i =>
              <Img
                fluid={i.image.childImageSharp.fluid}
                alt={`Picture of ${title}`}
                className="physical-image"
                key={i.image.childImageSharp.fluid.src}
              />
            )
          }
        </div>

        <div className="page-content">
          <div>{title}</div>
          <PostContent content={content}/>
          {buyLink && <Outlink href={buyLink}>Purchase Item</Outlink>}
        </div>
      </section>
    )
  }
}

PhysicalPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const PhysicalPost = ({data}) => {
  const {markdownRemark: post} = data
  return (
    <Layout backButton>
      <PhysicalPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        buyLink={post.frontmatter.bandcamp}
        images={post.frontmatter.images}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.excerpt}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

PhysicalPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PhysicalPost

export const pageQuery = graphql`
  query PhysicalPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(format: PLAIN, pruneLength: 200)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        bandcamp
        description
        images {
          image {
            childImageSharp{
              fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
