import React from "react"
import PropTypes from "prop-types"

const Outlink = ({href, children}) => {
  return (
    <div className="outlink">
      <span
      >
        *
      </span> <a
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    </div>
  )
}

Outlink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Outlink
